import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Box, Container, CircularProgress } from "@mui/material";
import Bio from "./Bio";
import PlayerSummary from "./PlayerSummary";
import KeyStats from "./KeyStats";
import BlockHeader from "./BlockHeader";
import RoleSummary from "./RoleSummary";
import ShotChartControls from "./ShotChartControls";
import CoachNotes from "./CoachNotes";
import ValuationBox from "./ValuationBox";
import axios from "axios";
import PlayerPageTabBar from "./PlayerPageTabBar";
import duplicateNamesMen from "../../src/data/duplicate-names-men.json";
import duplicateNamesWomen from "../../src/data/duplicate-names-women.json";
import { useState } from "react";
import { Switch, Tooltip, FormControlLabel } from "@mui/material";

export default function PlayerPage(props) {
  const navigate = useNavigate();
  const [boxscoreData, setBoxscoreData] = React.useState([]);
  const [shotData, setShotData] = React.useState([]);
  const [previousScores, setPreviousScores] = React.useState({
    prevScorerScore: 50,
    prevShooterScore: 50,
    prevFinisherScore: 50,
    prevPlaymakerScore: 50,
    prevRebounderScore: 50,
    prevDefenderScore: 50,
    prevRimprotectorScore: 50,
    prevImpactScore: 50,
  });

  const [loaded, setLoaded] = React.useState(false);

  const buildPortalPlayersList = async (player, league) => {
    // Default all players to not in portal
    player.portalStatus = "Not in Portal";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_STORAGE_BLOB_URL}/${league.substring(
          0,
          1
        )}-portal-list/portal-players.json`
      );
      const portalJSON = await response.json();
      for (let i = 0; i < portalJSON.length; i++) {
        if (
          player.name === portalJSON[i].name_synergy &&
          player.teamname === portalJSON[i].school_synergy
        ) {
          // if (league === "mens") {
          //   player.portalStatus = portalJSON[i].portal_status;
          //   break;
          // } else if (league === "womens") {
          //   player.portalStatus = "In Portal";
          //   break;
          // }
          player.portalStatus = portalJSON[i].portal_status;
          break;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const selectPlayerFromUrl = async () => {
    const url = window.location.href;
    const regex = /\/player\/([^\/]+)\/([^\/]+)\/([^\/]+)/;
    const match = url.match(regex);

    //path="/player/:league/:season/:playerid"
    const league = match[1];
    const season = match[2];
    const playerId = match[3];

    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playerdata_v2?league=${league}&playerid=${playerId}&season=${season}`
    );

    const currentData = data.data[0];
    await buildPortalPlayersList(currentData, league);
    props.updateSelectedLeague(league);
    props.updateSelectedPlayer(
      playerId,
      season,
      currentData.history,
      currentData.portalStatus,
      currentData.sportsRefUrl
    );
    setLoaded(true);
  };

  const fetchBoxscoreData = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playerdata_v2?league=${props.league}&playerid=${props.playerId}&season=${props.season}`
    );
    setBoxscoreData(data.data[0]);

    const currentData = data.data[0];

    if (props.loadingFromSearch) {
      await buildPortalPlayersList(currentData, props.league);
      props.updateSelectedPlayer(
        props.playerId,
        props.season,
        currentData.history,
        currentData.portalStatus,
        currentData.sportsRefUrl
      );
    }

    setLoaded(true); // render now before updating firstRender
    props.setLoadingFromSearch(false);

    if (props.firstRender) {
      props.updateFirstRender(false);
    }
    updatePreviousScores(
      Math.round(currentData.scorer),
      Math.round(currentData.shooter),
      Math.round(currentData.finisher),
      Math.round(currentData.playmaker),
      Math.round(currentData.rebounder),
      Math.round(currentData.defender),
      Math.round(currentData.rimprotector),
      Math.round(currentData.playervalue * 100)
    );
  };

  const fetchSeasonShotData = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/seasonshots?league=${props.league}&playerid=${props.playerId}&season=${props.season}`
    );
    setShotData(data.data.shots);
  };

  const updatePreviousScores = (
    newScorerScore,
    newShooterScore,
    newFinisherScore,
    newPlaymakerScore,
    newRebounderScore,
    newDefenderScore,
    newRimprotectorScore,
    newImpactScore
  ) => {
    setPreviousScores((prevState) => {
      return {
        ...prevState,
        prevScorerScore: newScorerScore,
        prevShooterScore: newShooterScore,
        prevFinisherScore: newFinisherScore,
        prevPlaymakerScore: newPlaymakerScore,
        prevRebounderScore: newRebounderScore,
        prevDefenderScore: newDefenderScore,
        prevRimprotectorScore: newRimprotectorScore,
        prevImpactScore: newImpactScore,
      };
    });
  };

  React.useEffect(() => {
    if (props.playerId === null) {
      setLoaded(false);
      selectPlayerFromUrl();
    } else {
      if (props.loadingFromSearch) {
        setLoaded(false);
      }
      fetchBoxscoreData();
      fetchSeasonShotData();
    }
  }, [props.league, props.playerId, props.season]);

  React.useEffect(() => {
    const handlePopstate = () => {
      setLoaded(false);
      selectPlayerFromUrl();
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Get stats from shot chart data when possible
  let makes2pt = 0,
    att2pt = 0;
  let makes3pt = 0,
    att3pt = 0;

  for (let i = 0; i < shotData.length; i++) {
    if (shotData[i].is3pt === "Yes") {
      att3pt++;
      if (shotData[i].outcome === "Make") {
        makes3pt++;
      }
    } else {
      att2pt++;
      if (shotData[i].outcome === "Make") {
        makes2pt++;
      }
    }
  }

  let attAll = att2pt + att3pt;
  let makesAll = makes2pt + makes3pt;

  let fgPct = ((makesAll / attAll) * 100).toFixed(1);
  if (attAll === 0) {
    fgPct = (0).toFixed(1);
  }

  let ftPct = ((boxscoreData.ftmade / boxscoreData.ftattempt) * 100).toFixed(1);
  if (boxscoreData.ftattempt === 0) {
    ftPct = (0).toFixed(1);
  }

  let twoPtPct = (
    boxscoreData.shot2percent ? boxscoreData.shot2percent * 100 : 0
  ).toFixed(1);

  let threePtPct = (
    boxscoreData.shot3percent ? boxscoreData.shot3percent * 100 : 0
  ).toFixed(1);

  const setPercentileColor = (stat) => {
    let color = "";
    if (stat > 0.85) {
      color = "rgb(47, 198, 138)";
    } else if (stat < 0.15) {
      color = "rgb(254, 86, 52)";
    }
    return color;
  };

  // scorer
  let ppg =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.points / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let ppgColor = setPercentileColor(boxscoreData.ppg_pctl);
  let fgPctColor = setPercentileColor(boxscoreData.fgpercent_pctl);
  let twoPtPctColor = setPercentileColor(boxscoreData.shot2percent_pctl);
  let ftPctColor = setPercentileColor(boxscoreData.ftpercent_pctl);
  let threePtPctColor = setPercentileColor(boxscoreData.shot3percent_pctl);
  let usageRate = (boxscoreData.usg_pct ? boxscoreData.usg_pct : 0).toFixed(1);
  let usageRateColor = setPercentileColor(boxscoreData.usg_pct_pctl);
  let pointsPerShot = (
    boxscoreData.pointspershot ? boxscoreData.pointspershot : 0
  ).toFixed(1);
  let pointsPerShotColor = setPercentileColor(boxscoreData.pointspershot_pctl);
  let fgaPerGame =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.fgattempt / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let fgaPerGameColor = setPercentileColor(boxscoreData.fga_perg_pctl);

  // shooter
  let ftag =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.ftattempt / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let ftagColor = setPercentileColor(boxscoreData.fta_perg_pctl);
  let ftmg =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.ftmade / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let ftmgColor = setPercentileColor(boxscoreData.ftm_perg_pctl);
  let threeAR =
    boxscoreData.fgattempt > 0
      ? (boxscoreData.shot3attempt / boxscoreData.fgattempt).toFixed(1)
      : (0).toFixed(1);
  let threeARColor = setPercentileColor(boxscoreData["3par_pctl"]);
  let threeMG =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.shot3made / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let threeMGColor = setPercentileColor(boxscoreData.threem_perg_pctl);
  let ssm = (
    boxscoreData.shotmaking ? boxscoreData.shotmaking * 100 : 0
  ).toFixed(1);
  let ssmColor = setPercentileColor(boxscoreData.shotmaking_pctl);

  // finisher
  let asq = (
    boxscoreData.averageshotquality ? boxscoreData.averageshotquality : 0
  ).toFixed(1);
  let asqColor = setPercentileColor(boxscoreData.averageshotquality_pctl);
  let ftar =
    boxscoreData.fgattempt > 0
      ? (boxscoreData.ftattempt / boxscoreData.fgattempt).toFixed(1)
      : (0).toFixed(1);
  let ftarColor = setPercentileColor(boxscoreData.ftr_pctl);
  // playmaker
  let tovPct = (boxscoreData.tov_pct ? boxscoreData.tov_pct : 0).toFixed(1);
  let tovPctColor;
  // higher turnover percentage is worse -> set percentiles manually
  if (boxscoreData.tov_pct_pctl < 0.15) {
    tovPctColor = "rgb(47, 198, 138)";
  } else if (boxscoreData.tov_pct_pctl > 0.85) {
    tovPctColor = "rgb(254, 86, 52)";
  }
  let atr = (
    boxscoreData.assiststurnover ? boxscoreData.assiststurnover : 0
  ).toFixed(1);
  let atrColor = setPercentileColor(boxscoreData.assiststurnover_pctl);
  let astPG =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.assists / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let astPGColor = setPercentileColor(boxscoreData.assists_perg_pctl);
  let pnrPPP =
    boxscoreData.PRBallHandlerPoss > 0
      ? (
          boxscoreData.PRBallHandlerPoints / boxscoreData.PRBallHandlerPoss
        ).toFixed(1)
      : (0).toFixed(1);
  let pnrPPPColor = setPercentileColor(boxscoreData.pnr_ppp_pctl);
  let pnrGame =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.PRBallHandlerPoints / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let pnrGameColor = setPercentileColor(boxscoreData.pnr_game_pctl);
  let isoPPP =
    boxscoreData.ISOInclPassesPoss > 0
      ? (
          boxscoreData.ISOInclPassesPoints / boxscoreData.ISOInclPassesPoss
        ).toFixed(1)
      : (0).toFixed(1);
  let isoPPPColor = setPercentileColor(boxscoreData.iso_ppp_pctl);
  let isoPG =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.ISOInclPassesPoss / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let isoPGColor = setPercentileColor(boxscoreData.iso_pg_pctl);
  // rebounder
  let rbdPG =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.totalreb / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let rbdPGColor = setPercentileColor(boxscoreData.totalreb_perg_pctl);
  let orbPG =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.offreb / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let orbPGColor = setPercentileColor(boxscoreData.offreb_perg_pctl);
  let drbPG =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.defreb / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let drbPGColor = setPercentileColor(boxscoreData.defreb_perg_pctl);
  let orebPct = (boxscoreData.orb_pct ? boxscoreData.orb_pct : 0).toFixed(1);
  let orebPctColor = setPercentileColor(boxscoreData.orb_pct_pctl);
  let drebPct = (boxscoreData.drb_pct ? boxscoreData.drb_pct : 0).toFixed(1);
  let drebPctColor = setPercentileColor(boxscoreData.drb_pct_pctl);
  let rebPct = (boxscoreData.trb_pct ? boxscoreData.trb_pct : 0).toFixed(1);
  let rebPctColor = setPercentileColor(boxscoreData.trb_pct_pctl);
  // defender
  let stlPg =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.steals / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let stlPgColor = setPercentileColor(boxscoreData.stl_perg_pctl);
  let blkPg =
    boxscoreData.gamesplayed > 0
      ? (boxscoreData.blocks / boxscoreData.gamesplayed).toFixed(1)
      : (0).toFixed(1);
  let blkPgColor = setPercentileColor(boxscoreData.blk_perg_pctl);
  let minsPlayed = boxscoreData.secondsplayed / 60;
  let dwsPg =
    minsPlayed > 0
      ? ((boxscoreData.dws / minsPlayed) * 40).toFixed(2)
      : (0).toFixed(1);
  let dwsPgColor = setPercentileColor(boxscoreData.dws_per40_pctl);
  let dbpm = (boxscoreData.dbpm ? boxscoreData.dbpm : 0).toFixed(1);
  let dbpmColor = setPercentileColor(boxscoreData.dbpm_pctl);
  // rim protector
  let blkPct = (boxscoreData.blk_pct ? boxscoreData.blk_pct : 0).toFixed(1);
  let blkPctColor = setPercentileColor(boxscoreData.blk_pct_pctl);
  let blkPf =
    boxscoreData.personalfoulcommitted > 0
      ? (boxscoreData.blocks / boxscoreData.personalfoulcommitted).toFixed(1)
      : 0;
  let blkPfColor = setPercentileColor(boxscoreData.blk_pf_pctl);

  // Hacky fix for shared headshots
  let headshotPath = `${
    process.env.REACT_APP_STORAGE_BLOB_URL
  }/${props.league.substring(0, 1)}-headshots/${boxscoreData.headshotName}.jpg`;
  let duplicateNamesList;
  if (props.league === "mens") {
    duplicateNamesList = duplicateNamesMen;
  } else {
    duplicateNamesList = duplicateNamesWomen;
  }
  for (let i = 0; i < duplicateNamesList.length; i++) {
    if (duplicateNamesList[i].name_bb_ref === boxscoreData.headshotName) {
      headshotPath = `${
        process.env.REACT_APP_STORAGE_BLOB_URL
      }/${props.league.substring(0, 1)}-headshots/${
        boxscoreData.headshotName
      } ${boxscoreData.teamname}.jpg`;
    }
  }

  return (
    <>
      {loaded ? (
        <div>
          <PlayerPageTabBar
            history={props.history}
            playerId={props.playerId}
            season={props.season}
            status={props.status}
            updateSelectedPlayer={props.updateSelectedPlayer}
            isMobile={props.isMobile}
            srUrl={boxscoreData.sportsRefUrl}
          ></PlayerPageTabBar>
          <Container
            maxWidth="1284px"
            sx={{
              paddingLeft: props.isMobile ? "" : "48px !important",
              paddingRight: props.isMobile ? "" : "48px !important",
              maxWidth: "1284px",
              marginTop: "25px",
            }}
          >
            {/* <Box
              m={3}
              sx={{
                marginLeft: props.isMobile ? "0px" : "",
                marginRight: props.isMobile ? "0px" : "",
                "& .MuiTypography-body1": {
                  fontSize: props.isMobile ? "12px" : "1rem",
                },
              }}
            > */}
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} md={7}>
                <Bio
                  isMobile={props.isMobile}
                  name={boxscoreData.name}
                  playerId={props.playerId}
                  school={boxscoreData.teamname}
                  favoritesListIncluded={props.favoritesListIncluded}
                  addFavorite={props.addFavorite}
                  removeFavorite={props.removeFavorite}
                  pos={boxscoreData.pos}
                  year={boxscoreData.playerclass}
                  height={boxscoreData.height}
                  weight={
                    boxscoreData.weight != 0 ? boxscoreData.weight + " lb" : ""
                  }
                  history={props.history}
                  headshotPath={headshotPath}
                  logoPath={
                    process.env.REACT_APP_STORAGE_BLOB_URL +
                    "/college-logos/" +
                    boxscoreData.teamname +
                    ".jpg"
                  }
                  status={props.status}
                  hometown={boxscoreData.hometown}
                  highSchool={boxscoreData.highschool}
                  league={props.league}
                  season={props.season}
                  searchPlayerList={props.searchPlayerList}
                  srURL={boxscoreData.sportsRefUrl}
                  scorerScore={Math.round(boxscoreData.scorer)}
                  previousScorerScore={
                    props.firstRender ? 50 : previousScores.prevScorerScore
                  }
                  shooterScore={Math.round(boxscoreData.shooter)}
                  previousShooterScore={
                    props.firstRender ? 50 : previousScores.prevShooterScore
                  }
                  finisherScore={Math.round(boxscoreData.finisher)}
                  previousFinisherScore={
                    props.firstRender ? 50 : previousScores.prevFinisherScore
                  }
                  playmakerScore={Math.round(boxscoreData.playmaker)}
                  previousPlaymakerScore={
                    props.firstRender ? 50 : previousScores.prevPlaymakerScore
                  }
                  rebounderScore={Math.round(boxscoreData.rebounder)}
                  previousRebounderScore={
                    props.firstRender ? 50 : previousScores.prevRebounderScore
                  }
                  defenderScore={Math.round(boxscoreData.defender)}
                  previousDefenderScore={
                    props.firstRender ? 50 : previousScores.prevDefenderScore
                  }
                  rimprotectorScore={Math.round(boxscoreData.rimprotector)}
                  previousRimprotectorScore={
                    props.firstRender
                      ? 50
                      : previousScores.prevRimprotectorScore
                  }
                  impactScore={Math.round(boxscoreData.playervalue * 100)}
                  previousImpactScore={
                    props.firstRender ? 50 : previousScores.prevImpactScore
                  }
                  headerMenuOpen={props.headerMenuOpen}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <PlayerSummary
                  scorerScore={Math.round(boxscoreData.scorer)}
                  previousScorerScore={
                    props.firstRender ? 50 : previousScores.prevScorerScore
                  }
                  shooterScore={Math.round(boxscoreData.shooter)}
                  previousShooterScore={
                    props.firstRender ? 50 : previousScores.prevShooterScore
                  }
                  finisherScore={Math.round(boxscoreData.finisher)}
                  previousFinisherScore={
                    props.firstRender ? 50 : previousScores.prevFinisherScore
                  }
                  playmakerScore={Math.round(boxscoreData.playmaker)}
                  previousPlaymakerScore={
                    props.firstRender ? 50 : previousScores.prevPlaymakerScore
                  }
                  rebounderScore={Math.round(boxscoreData.rebounder)}
                  previousRebounderScore={
                    props.firstRender ? 50 : previousScores.prevRebounderScore
                  }
                  defenderScore={Math.round(boxscoreData.defender)}
                  previousDefenderScore={
                    props.firstRender ? 50 : previousScores.prevDefenderScore
                  }
                  rimprotectorScore={Math.round(boxscoreData.rimprotector)}
                  previousRimprotectorScore={
                    props.firstRender
                      ? 50
                      : previousScores.prevRimprotectorScore
                  }
                  impactScore={Math.round(boxscoreData.playervalue * 100)}
                  previousImpactScore={
                    props.firstRender ? 50 : previousScores.prevImpactScore
                  }
                />
              </Grid>
              {props.isMobile && (
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  container
                  justifyContent="flex-end"
                  alignContent="flex-start"
                >
                  <KeyStats
                    isMobile={props.isMobile}
                    season={props.season}
                    ppg={(
                      boxscoreData.points / boxscoreData.gamesplayed
                    ).toFixed(1)}
                    rpg={(
                      boxscoreData.totalreb / boxscoreData.gamesplayed
                    ).toFixed(1)}
                    apg={(
                      boxscoreData.assists / boxscoreData.gamesplayed
                    ).toFixed(1)}
                    fgpct={fgPct}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={6.5}
                sx={{
                  "& .MuiPaper-root": {
                    //fontSize: props.isMobile ? "12px" : "1rem",
                    padding: props.isMobile ? "0px" : "10px",
                    paddingBottom: props.isMobile ? "10px" : "20px",
                  },
                }}
              >
                <BlockHeader text="Notes"></BlockHeader>
                <CoachNotes
                  addNote={props.addNote}
                  srURL={boxscoreData.sportsRefUrl}
                  username={props.username}
                  isMobile={props.isMobile}
                ></CoachNotes>
                <BlockHeader text="Role Analysis"></BlockHeader>
                <RoleSummary
                  roleName="Scorer"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.scorer)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevScorerScore
                  }
                  stat1Name="PPG"
                  stat2Name="FG%"
                  stat3Name="2PFG%"
                  stat4Name="FT%"
                  stat5Name="3P%"
                  stat6Name="PPS"
                  stat7Name="USG%"
                  stat8Name="FGA/G"
                  stat1Value={ppg}
                  stat2Value={fgPct}
                  stat3Value={twoPtPct}
                  stat4Value={ftPct}
                  stat5Value={threePtPct}
                  stat6Value={pointsPerShot}
                  stat7Value={usageRate}
                  stat8Value={fgaPerGame}
                  tooltip1="Points Per Game"
                  tooltip2="Field Goal Percentage"
                  tooltip3="Field Goal Percentage on 2 Point Shots"
                  tooltip4="Free Throw Percentage"
                  tooltip5="3 Point Percentage"
                  tooltip6="Points Per Shot: total points scored / total field goal attempts"
                  tooltip7="Usage Rate"
                  tooltip8="Field Goal Attempts Per Game"
                  stat1Color={ppgColor}
                  stat2Color={fgPctColor}
                  stat3Color={twoPtPctColor}
                  stat4Color={ftPctColor}
                  stat5Color={threePtPctColor}
                  stat6Color={pointsPerShotColor}
                  stat7Color={usageRateColor}
                  stat8Color={fgaPerGameColor}
                />
                <RoleSummary
                  roleName="Shooter"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.shooter)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevShooterScore
                  }
                  stat1Name="FTA/G"
                  stat2Name="FTM/G"
                  stat3Name="3PAR"
                  stat4Name="3PM/G"
                  stat5Name="SSM"
                  stat1Value={ftag}
                  stat2Value={ftmg}
                  stat3Value={threeAR}
                  stat4Value={threeMG}
                  stat5Value={ssm}
                  tooltip1="Free Throw Attempts Per Game"
                  tooltip2="Free Throws Made Per Game"
                  tooltip3="% of Field Goal Attempts that are 3-pointers"
                  tooltip4="3 Pointers Made Per Game"
                  tooltip5="Synergy Shot Making: PPS above expectation"
                  stat1Color={ftagColor}
                  stat2Color={ftmgColor}
                  stat3Color={threeARColor}
                  stat4Color={threeMGColor}
                  stat5Color={ssmColor}
                />
                <RoleSummary
                  roleName="Finisher"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.finisher)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevFinisherScore
                  }
                  stat1Name="FG%"
                  stat2Name="2PFG%"
                  stat3Name="FTAR"
                  stat4Name="OREB%"
                  stat5Name="SSQ"
                  stat1Value={fgPct}
                  stat2Value={twoPtPct}
                  stat3Value={ftar}
                  stat4Value={orebPct}
                  stat5Value={asq}
                  tooltip1="Field Goal Percentage"
                  tooltip2="Field Goal Percentage on 2 Point Shots"
                  tooltip3="FTA-Rate: how often a player gets to the free-throw line relative to their field goal attempts"
                  tooltip4="Total Offensive Rebounds / Total Offensive Rebound Opportunities"
                  tooltip5="Synergy Shot Quality: expected points per shot"
                  stat1Color={fgPctColor}
                  stat2Color={twoPtPctColor}
                  stat3Color={ftarColor}
                  stat4Color={orebPctColor}
                  stat5Color={asqColor}
                />
                <RoleSummary
                  roleName="Playmaker"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.playmaker)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevPlaymakerScore
                  }
                  stat1Name="TOV%"
                  stat2Name="ATR"
                  stat3Name="AST/G"
                  stat4Name="PNR PPP"
                  stat5Name="PNR Game"
                  stat6Name="ISO PPP"
                  stat7Name="ISO/G"
                  stat1Value={tovPct}
                  stat2Value={atr}
                  stat3Value={astPG}
                  stat4Value={pnrPPP}
                  stat5Value={pnrGame}
                  stat6Value={isoPPP}
                  stat7Value={isoPG}
                  tooltip1="Turnover Percentage"
                  tooltip2="Assist to Turnover Ratio"
                  tooltip3="Assists Per Game"
                  tooltip4="Pick and Roll Ball Handler PPP"
                  tooltip5="Pick and Roll Ball Handler Points Per Game: average number of points a ball handler scores in pick-and-roll situations per game"
                  tooltip6="Isolations Including Passes PPP"
                  tooltip7="Isolations Including Passes Per Game: average number of isolation plays a player participates in per game"
                  stat1Color={tovPctColor}
                  stat2Color={atrColor}
                  stat3Color={astPGColor}
                  stat4Color={pnrPPPColor}
                  stat5Color={pnrGameColor}
                  stat6Color={isoPPPColor}
                  stat7Color={isoPGColor}
                />
                <RoleSummary
                  roleName="Rebounder"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.rebounder)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevRebounderScore
                  }
                  stat1Name="RBD/G"
                  stat2Name="ORB/G"
                  stat3Name="DRB/G"
                  stat4Name="REB%"
                  stat5Name="OREB%"
                  stat6Name="DREB%"
                  stat1Value={rbdPG}
                  stat2Value={orbPG}
                  stat3Value={drbPG}
                  stat4Value={rebPct}
                  stat5Value={orebPct}
                  stat6Value={drebPct}
                  tooltip1="Rebounds Per Game"
                  tooltip2="Offensive Rebounds Per Game"
                  tooltip3="Defensive Rebounds Per Game"
                  tooltip4="Total Rebounds / Total Rebound Opportunities"
                  tooltip5="Total Offensive Rebounds / Total Offensive Rebound Opportunities"
                  tooltip6="Total Defensive Rebounds / Total Defensive Rebound Opportunities"
                  stat1Color={rbdPGColor}
                  stat2Color={orbPGColor}
                  stat3Color={drbPGColor}
                  stat4Color={rebPctColor}
                  stat5Color={orebPctColor}
                  stat6Color={drebPctColor}
                />
                <RoleSummary
                  roleName="Defender"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.defender)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevDefenderScore
                  }
                  stat1Name="STL/G"
                  stat2Name="BLK/G"
                  stat3Name="DWS/40"
                  stat4Name="DBPM"
                  stat1Value={stlPg}
                  stat2Value={blkPg}
                  stat3Value={dwsPg}
                  stat4Value={dbpm}
                  tooltip1="Steals Per Game"
                  tooltip2="Blocks Per Game"
                  tooltip3="Defensive Win Shares Per 40 Minutes"
                  tooltip4="Defensive Box Plus Minus: positive indicates better defensive performance"
                  stat1Color={stlPgColor}
                  stat2Color={blkPgColor}
                  stat3Color={dwsPgColor}
                  stat4Color={dbpmColor}
                />
                <RoleSummary
                  roleName="Rim Protector"
                  isMobile={props.isMobile}
                  roleScore={Math.round(boxscoreData.rimprotector)}
                  previousScore={
                    props.firstRender ? 0 : previousScores.prevRimprotectorScore
                  }
                  stat1Name=""
                  stat2Name="BLK/G"
                  stat3Name="BLK%"
                  stat4Name="BLK/PF"
                  stat1Value=""
                  stat2Value={blkPg}
                  stat3Value={blkPct}
                  stat4Value={blkPf}
                  tooltip2="Blocks Per Game"
                  tooltip3="Block Percentage"
                  tooltip4="Block to Personal Foul Ratio"
                  stat2Color={blkPgColor}
                  stat3Color={blkPctColor}
                  stat4Color={blkPfColor}
                />
              </Grid>
              {!props.isMobile && (
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  container
                  justifyContent="flex-end"
                  alignContent="flex-start"
                >
                  <KeyStats
                    isMobile={props.isMobile}
                    season={props.season}
                    ppg={(
                      boxscoreData.points / boxscoreData.gamesplayed
                    ).toFixed(1)}
                    rpg={(
                      boxscoreData.totalreb / boxscoreData.gamesplayed
                    ).toFixed(1)}
                    apg={(
                      boxscoreData.assists / boxscoreData.gamesplayed
                    ).toFixed(1)}
                    fgpct={fgPct}
                  />
                  <ValuationBox
                    isMobile={props.isMobile}
                    estcomp={boxscoreData.money}
                    currcomp={null}
                    impactscore={(boxscoreData.playervalue * 100).toFixed(1)}
                  />
                  <ShotChartControls
                    isMobile={props.isMobile}
                    league={props.league}
                    playerId={props.playerId}
                    season={props.season}
                  />
                </Grid>
              )}
              {props.isMobile && (
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  container
                  justifyContent="flex-end"
                  alignContent="flex-start"
                  sx={{ marginTop: props.isMobile ? "-35px" : "" }}
                >
                  <ShotChartControls
                    isMobile={props.isMobile}
                    league={props.league}
                    playerId={props.playerId}
                    season={props.season}
                  />
                </Grid>
              )}
            </Grid>
            {/* </Box> */}
          </Container>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
}
