import { Card, Typography, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import BlockHeader from "./BlockHeader";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
}));

function getNILNumber(nil) {
  if (nil >= 0 && nil < 50) {
    return 0;
  } else if (nil >= 50 && nil < 75) {
    return 1;
  } else if (nil >= 75 && nil < 85) {
    return 2;
  } else if (nil >= 85 && nil < 95) {
    return 3;
  } else if (nil >= 95 && nil < 97.5) {
    return 4;
  } else {
    return 5;
  }
}

function GreenDollarSigns({ numberOfDollars, numberOfGreen }) {
  const dollarSigns = Array.from({ length: numberOfDollars }, (_, index) => (
    <span
      key={index}
      style={{
        color: index < numberOfGreen ? "green" : "gray",
      }}
    >
      $
    </span>
  ));

  return <div>{dollarSigns}</div>;
}

function ValuationBox(props) {
  return (
    <Box sx={{ width: "100%", marginTop: "24px" }}>
      <BlockHeader text="Valuation"></BlockHeader>
      <Card sx={{ padding: props.isMobile ? "0px" : "" }}>
        <div className="KeyStatsTable">
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTypography-body2": {
                    fontSize: props.isMobile ? ".8rem" : ".875rem",
                  },
                }}
              >
                <TableCell align="center">
                  <Typography
                    color="gray"
                    variant="body2"
                    textTransform="uppercase"
                  >
                    Est. Comp
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="gray"
                    variant="body2"
                    textTransform="uppercase"
                  >
                    Curr Comp
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="gray"
                    variant="body2"
                    textTransform="uppercase"
                  >
                    Impact Score
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {isNaN(props.estcomp) ? (
                  <StyledTableCell>
                    <Skeleton
                      width="50%"
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <StyledTableCell>--</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    --
                    {/* <GreenDollarSigns
                      numberOfDollars={6}
                      numberOfGreen={props.estcomp}
                    /> */}
                  </StyledTableCell>
                )}
                {isNaN(props.currcomp) || props.currcomp == null ? (
                  <StyledTableCell align="center">--</StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    {props.currcomp}
                  </StyledTableCell>
                )}
                {isNaN(props.impactscore) ? (
                  <StyledTableCell>
                    <Skeleton
                      width="50%"
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <StyledTableCell>--</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    {props.impactscore}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Card>
    </Box>
  );
}

export default ValuationBox;
