import * as React from "react";
import { fetchUserAttributes } from "@aws-amplify/auth";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import PlayerPage from "./PlayerPage";
import PlayerTable from "./PlayerTable";
import axios from "axios";
import {
  Switch,
  Route,
  useRouteMatch,
  useMatch,
  Routes,
  Router,
} from "react-router-dom";
// import { useControls } from "react-zoom-pan-pinch";

export default function TransferIQ(props) {
  // resize window
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth < props.theme.breakpoints.values.sm
  );

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < props.theme.breakpoints.values.sm);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [userId, setUserId] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [loadingFromSearch, setLoadingFromSearch] = React.useState(false);

  React.useEffect(() => {
    fetchUserInfo();
  }, []);

  // get current user
  const fetchUserInfo = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      setUserId(userAttributes.email);
      setUsername(userAttributes.name);
    } catch (err) {
      console.log(err);
    }
  };

  const [playerList, setPlayerList] = React.useState([]);
  const [searchPlayerList, setSearchPlayerList] = React.useState([]);
  const [portalList, setPortalList] = React.useState([]);

  const fetchPlayerList = async (signal) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FLASK_URL}/playertable_v2?league=${transferIqOptions.selectedLeague}`,
        { signal }
      );

      buildPortalPlayersList(response.data);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted");
      } else {
        console.error("Error:", error);
      }
    }
  };

  const buildPortalPlayersList = (allPlayers) => {
    // defualt all players to not in portal
    allPlayers.forEach((player) => (player.portalStatus = "Not in Portal"));

    fetch(
      `${process.env.REACT_APP_STORAGE_BLOB_URL
      }/${transferIqOptions.selectedLeague.substring(
        0,
        1
      )}-portal-list/portal-players.json`
    )
      .then((response) => {
        return response.json();
      })
      .then((portalJSON) => {
        // put portal players into a set to prevent duplicates
        const portalList = new Set();

        // for every player in the JSON, find if that player is in the allPlayersList state
        for (let i = 0; i < portalJSON.length; i++) {
          for (let j = 0; j < allPlayers.length; j++) {
            if (
              allPlayers[j].name === portalJSON[i].name_synergy &&
              allPlayers[j].teamname === portalJSON[i].school_synergy
            ) {
              // update player's portalStatus attribute if found in JSON
              // if (transferIqOptions.selectedLeague === "mens") {
              //   allPlayers[j].portalStatus = portalJSON[i].portal_status;
              //   portalList.add(allPlayers[j]);
              //   break;
              // } else if (transferIqOptions.selectedLeague === "womens") {
              //   allPlayers[j].portalStatus = "In Portal";
              //   portalList.add(allPlayers[j]);
              //   break;
              // }
              allPlayers[j].portalStatus = portalJSON[i].portal_status;
              portalList.add(allPlayers[j]);
              break;
            }
          }
        }

        setPortalList(portalList);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // update allPlayersList state with updated list with portalStatus
    setPlayerList(allPlayers);
  };
  const [favoritesList, setFavoritesList] = React.useState([]);
  // get player list and set it
  const fetchFavoritePlayers = async () => {
    try {
      if (userId != null && userId !== "") {
        const response = await axios.get(
          `${process.env.REACT_APP_FLASK_URL}/favorites?league=` +
          transferIqOptions.selectedLeague +
          "&userid=" +
          userId
        );
        if (
          response.data.favorites ==
          undefined ||
          userId == null
        ) {
          setFavoritesList([]);
        } else {
          setFavoritesList(
            response.data.favorites
          );
        }
      }
    } catch (error) {
      console.error("Error fetching favorite players:", error);
      setFavoritesList([]);
    }
  };

  const loginURL =
    "https://" +
    process.env.REACT_APP_ENV +
    ".azurewebsites.net/.auth/login/aad?post_login_redirect_url=/";

  React.useEffect(() => {
    if (userId !== "" && userId !== null && userId !== undefined) {
      fetchFavoritePlayers();
    }
  }, [userId]);

  // add a favorite to the list
  const addFavorite = async (sr_url) => {
    const data = await axios.post(
      `${process.env.REACT_APP_FLASK_URL}/addfavorite?league=` +
      transferIqOptions.selectedLeague +
      "&userid=" +
      userId +
      "&sr_url=" +
      sr_url
    );
    fetchFavoritePlayers();
  };

  // remove a favorite from the list
  const removeFavorite = async (sr_url) => {
    const data = await axios.post(
      `${process.env.REACT_APP_FLASK_URL}/removefavorite?league=` +
      transferIqOptions.selectedLeague +
      "&userid=" +
      userId +
      "&sr_url=" +
      sr_url
    );
    fetchFavoritePlayers();
  };

  // add note to the coach notes
  const addNote = async (srURL, currentNotes) => {
    const data = await axios.post(
      `${process.env.REACT_APP_FLASK_URL}/addnote?sr_url=` +
      srURL +
      "&userid=" +
      userId +
      "&comment=" +
      currentNotes
    );
  };

  const currentYear = new Date().getFullYear();
  const [transferIqOptions, setTransferIqOptions] = React.useState({
    selectedLeague: "mens",
    selectedSeason: currentYear - 1 + "-" + currentYear,
    selectedPlayerId: null,
    selectedPlayerSeason: null,
    selectedPlayerHistory: null,
    selectedPlayerStatus: null,
    selectedPlayerSrUrl: null
  });

  const [playerTableState, setPlayerTableState] = React.useState({
    inPortalChecked: false,
    page: 0,
    positionFilters: [],
    schoolFilters: [],
    confFilters: [],
    stateFilters: [],
    classFilters: [],
    roleFilters: [],
    savedPlayerFilter: false,
    sortState: [],
    mobileSecondaryFilters: [],
  });

  const [firstRenderState, setFirstRenderState] = React.useState(true);

  const [sortModel, setSortModel] = React.useState([
    /*{
      field: 'shooter',
      sort: 'desc'
    }*/
  ]);

  const updateSortModel = (newModel) => {
    setSortModel(newModel);
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        page: 0,
      };
    });
  };

  const handleSeasonSelect = (season) => {
    setTransferIqOptions((prevState) => {
      return {
        ...prevState,
        selectedSeason: season,
      };
    });
  };

  React.useEffect(() => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        inPortalChecked: false,
        page: 0,
      };
    });
    fetchFavoritePlayers();
  }, [transferIqOptions.selectedSeason]);

  // React.useEffect(() => {
  //   fetchFavoritePlayers();
  //   setPlayerList([]);
  //   fetchPlayerList();
  // }, [transferIqOptions.selectedLeague]);

  React.useEffect(() => {
    const fetchController = new AbortController();
    const fetchSignal = fetchController.signal;

    fetchFavoritePlayers();

    setPlayerList([]);
    setSearchPlayerList([]);

    const fetchPlayerListWithSignal = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FLASK_URL}/playertable_v2?league=${transferIqOptions.selectedLeague}`,
          { signal: fetchSignal }
        );

        buildPortalPlayersList(response.data);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          console.error("Error:", error);
        }
      }
    };

    const fetchSearchPlayerListWithSignal = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FLASK_URL}/searchplayerlist?league=${transferIqOptions.selectedLeague}`,
          { signal: fetchSignal }
        );
        setSearchPlayerList(response.data);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          console.error("Error:", error);
        }
      }
    };
    fetchSearchPlayerListWithSignal();

    return () => {
      fetchController.abort();
    };
  }, [transferIqOptions.selectedLeague]);

  const handleLeagueSelect = (league) => {
    setTransferIqOptions((prevState) => {
      return {
        ...prevState,
        selectedLeague: league,
      };
    });
    window.scrollTo(0, 0);
  };

  const handlePlayerSelect = (playerId, season, history, status, sportsRefUrl) => {
    setTransferIqOptions((prevState) => {
      return {
        ...prevState,
        selectedPlayerId: playerId,
        selectedPlayerSeason: season,
        selectedPlayerHistory: history,
        selectedPlayerStatus: status,
        selectedPlayerSrUrl: sportsRefUrl
      };
    });
    window.scrollTo(0, 0);
  };

  const handleInPortalToggle = (event) => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        inPortalChecked: event.target.checked,
        page: 0,
      };
    });
  };

  const handleSavedPlayerFilterUpdate = (event) => {
    setPlayerTableState((prevState) => {
      const prevSaved = playerTableState.savedPlayerFilter;
      return {
        ...prevState,
        savedPlayerFilter: !prevSaved,
        page: 0,
      };
    });
  };

  React.useEffect(() => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        inPortalChecked: false,
        page: 0,
      };
    });
  }, [transferIqOptions.selectedSeason]);

  const handleTablePageChange = (newPage) => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        page: newPage,
      };
    });
  };

  const handlePositionFiltersUpdate = (position) => {
    setPlayerTableState((prevState) => {
      const isSelected = prevState.positionFilters.includes(position);
      const newSelection = isSelected
        ? prevState.positionFilters.filter(
          (currentPosition) => currentPosition !== position
        )
        : [...prevState.positionFilters, position];

      return {
        ...prevState,
        page: 0,
        positionFilters: newSelection,
        mobileSecondaryFilters: newSelection,
      };
    });
  };

  const handleSchoolFiltersUpdate = (school) => {
    setPlayerTableState((prevState) => {
      const isSelected = prevState.schoolFilters.includes(school);
      const newSelection = isSelected
        ? prevState.schoolFilters.filter(
          (currentSchool) => currentSchool !== school
        )
        : [...prevState.schoolFilters, school];

      return {
        ...prevState,
        page: 0,
        schoolFilters: newSelection,
        mobileSecondaryFilters: newSelection,
      };
    });
  };

  const handleFilterOneSchool = (event, school) => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        positionFilters: [],
        schoolFilters: [school],
        confFilters: [],
        stateFilters: [],
        classFilters: [],
        roleFilters: [],
        inPortalChecked: event.target.unchecked,
        page: 0,
      };
    });
  };

  const handleConfFiltersUpdate = (conf) => {
    setPlayerTableState((prevState) => {
      const isSelected = prevState.confFilters.includes(conf);
      const newSelection = isSelected
        ? prevState.confFilters.filter((currentConf) => currentConf !== conf)
        : [...prevState.confFilters, conf];
      return {
        ...prevState,
        page: 0,
        confFilters: newSelection,
        mobileSecondaryFilters: newSelection,
      };
    });
  };

  const handleStateFiltersUpdate = (state) => {
    setPlayerTableState((prevState) => {
      const isSelected = prevState.stateFilters.includes(state);
      const newSelection = isSelected
        ? prevState.stateFilters.filter(
          (currentState) => currentState !== state
        )
        : [...prevState.stateFilters, state];
      return {
        ...prevState,
        page: 0,
        stateFilters: newSelection,
        mobileSecondaryFilters: newSelection,
      };
    });
  };

  const handleClassFiltersUpdate = (selectedClass) => {
    setPlayerTableState((prevState) => {
      const isSelected = prevState.classFilters.includes(selectedClass);
      const newSelection = isSelected
        ? prevState.classFilters.filter(
          (currentClass) => currentClass !== selectedClass
        )
        : [...prevState.classFilters, selectedClass];

      return {
        ...prevState,
        page: 0,
        classFilters: newSelection,
        mobileSecondaryFilters: newSelection,
      };
    });
  };

  const handleRoleFiltersUpdate = (key, deleteCount, replacement) => {
    setPlayerTableState((prevState) => {
      // add new role filter to state
      if (deleteCount == 0)
        return {
          ...prevState,
          roleFilters: [...prevState.roleFilters, replacement],
        };
      // else, filter already exists, need to update it
      else {
        // get current filters and replace the new one using key comparison
        const newFilters = prevState.roleFilters.map((filter) => {
          if (filter.key == key) return replacement;
          else return filter;
        });
        return {
          ...prevState,
          page: 0,
          roleFilters: newFilters,
        };
      }
    });
  };

  const handleClearFilters = () => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        page: 0,
        positionFilters: [],
        schoolFilters: [],
        confFilters: [],
        stateFilters: [],
        classFilters: [],
        roleFilters: [],
        savedPlayerFilter: false,
        mobileSecondaryFilters: [],
      };
    });
  };

  const handleRemovePositionFilter = (positionToRemove) => {
    const newPositionArray = playerTableState.positionFilters.filter(
      (item) => item !== positionToRemove
    );
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        positionFilters: newPositionArray,
        mobileSecondaryFilters: newPositionArray,
      };
    });
  };

  const handleRemoveSchoolFilter = (schoolToRemove) => {
    const newSchoolArray = playerTableState.schoolFilters.filter(
      (item) => item !== schoolToRemove
    );
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        schoolFilters: newSchoolArray,
        mobileSecondaryFilters: newSchoolArray,
      };
    });
  };

  const handleClearSchoolFilters = () => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        page: 0,
        schoolFilters: [],
      };
    });
  };

  const handleRemoveConfFilter = (confToRemove) => {
    const newConfArray = playerTableState.confFilters.filter(
      (item) => item !== confToRemove
    );
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        confFilters: newConfArray,
        mobileSecondaryFilters: newConfArray,
      };
    });
  };

  const handleRemoveStateFilter = (stateToRemove) => {
    const newStateArray = playerTableState.stateFilters.filter(
      (item) => item !== stateToRemove
    );
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        stateFilters: newStateArray,
        mobileSecondaryFilters: newStateArray,
      };
    });
  };

  const handleClearStateFilters = () => {
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        stateFilters: [],
      };
    });
  };

  const handleRemoveClassFilter = (classToRemove) => {
    const newClassArray = playerTableState.classFilters.filter(
      (item) => item !== classToRemove
    );
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        classFilters: newClassArray,
        mobileSecondaryFilters: newClassArray,
      };
    });
  };

  const handleRemoveRoleFilter = (index) => {
    let newFilters = [...playerTableState.roleFilters];
    newFilters.splice(index, 1);
    setPlayerTableState((prevState) => {
      return {
        ...prevState,
        roleFilters: newFilters,
        mobileSecondaryFilters: newFilters,
      };
    });
  };

  const handlePlayerPageRender = (isFirstRender) => {
    setFirstRenderState(isFirstRender);
  };

  const [headerMenuOpen, setHeaderMenuOpen] = React.useState(false);
  return (
    // <h1>hello</h1>
    <div>
      <Header
        isMobile={isMobile}
        userId={userId}
        setUserId={setUserId}
        username={username}
        playerId={transferIqOptions.selectedPlayerId}
        league={transferIqOptions.selectedLeague}
        season={transferIqOptions.selectedPlayerSeason}
        playerList={searchPlayerList}
        portalList={portalList}
        updateSelectedPlayer={handlePlayerSelect}
        updateSelectedLeague={handleLeagueSelect}
        updateFirstRender={handlePlayerPageRender}
        setLoadingFromSearch={setLoadingFromSearch}
        setHeaderMenuOpen={setHeaderMenuOpen}
        signOutHandler={props.signOutHandler}
      />
      <Routes>
        <Route
          //path="/player/:playerid/:season/:league"
          path="/player/:league/:season/:playerid"
          element={
            <>
              <PlayerPage
                isMobile={isMobile}
                league={transferIqOptions.selectedLeague}
                playerId={transferIqOptions.selectedPlayerId}
                favoritesListIncluded={favoritesList.includes(
                  transferIqOptions.selectedPlayerSrUrl
                )}
                addFavorite={addFavorite}
                removeFavorite={removeFavorite}
                season={transferIqOptions.selectedPlayerSeason}
                history={transferIqOptions.selectedPlayerHistory}
                status={transferIqOptions.selectedPlayerStatus}
                updateSelectedPlayer={handlePlayerSelect}
                updateSelectedSeason={handleSeasonSelect}
                updateSelectedLeague={handleLeagueSelect}
                firstRender={firstRenderState}
                updateFirstRender={handlePlayerPageRender}
                playerList={playerList}
                searchPlayerList={searchPlayerList}
                setLoadingFromSearch={setLoadingFromSearch}
                loadingFromSearch={loadingFromSearch}
                headerMenuOpen={headerMenuOpen}
                //addNote={addNote}
                userId={userId}
                username={username}
              />
            </>
          }
        ></Route>
        <Route
          path=""
          element={
            <PlayerTable
              isMobile={isMobile}
              userId={userId}
              username={username}
              league={transferIqOptions.selectedLeague}
              season={transferIqOptions.selectedSeason}
              favoritesList={favoritesList}
              addFavorite={addFavorite}
              removeFavorite={removeFavorite}
              updateSelectedSeason={handleSeasonSelect}
              updateSelectedPlayer={handlePlayerSelect}
              updatePositionFilters={handlePositionFiltersUpdate}
              removePositionFilter={handleRemovePositionFilter}
              positionFilters={playerTableState.positionFilters}
              updateSchoolFilters={handleSchoolFiltersUpdate}
              removeSchoolFilter={handleRemoveSchoolFilter}
              clearSchoolFilters={handleClearSchoolFilters}
              schoolFilters={playerTableState.schoolFilters}
              filterOneSchoolOnly={handleFilterOneSchool}
              updateConfFilters={handleConfFiltersUpdate}
              removeConfFilter={handleRemoveConfFilter}
              confFilters={playerTableState.confFilters}
              updateStateFilters={handleStateFiltersUpdate}
              removeStateFilter={handleRemoveStateFilter}
              clearStateFilters={handleClearStateFilters}
              stateFilters={playerTableState.stateFilters}
              updateClassFilters={handleClassFiltersUpdate}
              removeClassFilter={handleRemoveClassFilter}
              classFilters={playerTableState.classFilters}
              updateRoleFilters={handleRoleFiltersUpdate}
              removeRoleFilter={handleRemoveRoleFilter}
              roleFilters={playerTableState.roleFilters}
              clearFilters={handleClearFilters}
              updateInPortalToggle={handleInPortalToggle}
              inPortalChecked={playerTableState.inPortalChecked}
              updatePageNum={handleTablePageChange}
              savedPlayerFilter={playerTableState.savedPlayerFilter}
              handleSavedPlayerFilterUpdate={handleSavedPlayerFilterUpdate}
              page={playerTableState.page}
              updateFirstRender={handlePlayerPageRender}
              sortModel={sortModel}
              updateSortModel={updateSortModel}
              secondaryFilters={playerTableState.mobileSecondaryFilters}
              playerList={playerList}
              headerMenuOpen={headerMenuOpen}
            />
          }
        ></Route>
      </Routes>
      <Footer isMobile={isMobile} />
    </div>
  );
}
