import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
  Button,
  Paper,
  InputBase,
  MenuItem,
  ListItemText,
  InputAdornment,
  Select,
  FormControl,
} from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { FixedSizeList } from "react-window";
import axios from "axios";
import duplicateNamesMen from "../../src/data/duplicate-names-men.json";
import duplicateNamesWomen from "../../src/data/duplicate-names-women.json";
import Fuse from "fuse.js";

const StyledBioText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px !important",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.95rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.05rem",
  },
}));

const StyledLogo = styled(CardMedia)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "32px",
  },
  [theme.breakpoints.up("md")]: {
    height: "42px",
  },
}));

const StyledPlayerName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px !important",
    fontWeight: "bold",
    lineHeight: 1.1,
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
}));

const Search = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  border: "1.5px solid #d9d9d9",
  position: "relative",
}));

const SearchMobile = styled("div")(({ theme }) => ({
  transform: "scale(0.8)",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  border: "1px solid #ADADAD",
  marginTop: "5px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "10px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
      paddingLeft: "5px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      paddingLeft: `calc(${theme.spacing(1)})`,
    },
  },
}));

const StyledInputBaseMobile = styled(InputBase)(({ theme }) => ({
  color: "#5B5B5B",
  fontSize: "16px",
  "& .MuiInputBase-input": {
    padding: "4px 8px 4px 32px",
    fontSize: "16px",
    color: "#5B5B5B",
    opacity: 1,
    width: "88px",
  },
  "& .MuiInputAdornment-positionStart": {
    position: "absolute",
    top: "50%",
  },
}));

function ModalBio(props) {
  const [headshotLoaded, setHeadshotLoaded] = React.useState(false);
  const [logoLoaded, setLogoLoaded] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [seasons, setSeasons] = React.useState([]);

  const options = {
    includeScore: true,
    findAllMatches: false,
    keys: ["name"],
    threshold: 0.4,
  };

  const fuse = new Fuse(props.searchPlayerList, options);

  const buildPortalPlayersList = async (player, league) => {
    // Default all players to not in portal
    player.status = "Not in Portal";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_STORAGE_BLOB_URL}/${league.substring(
          0,
          1
        )}-portal-list/portal-players.json`
      );
      const portalJSON = await response.json();
      for (let i = 0; i < portalJSON.length; i++) {
        if (
          player.name === portalJSON[i].name_synergy &&
          player.teamname === portalJSON[i].school_synergy
        ) {
          // if (league === "mens") {
          //   player.status = portalJSON[i].portalStatus;
          //   break;
          // } else if (league === "womens") {
          //   player.status = "In Portal";
          //   break;
          // }
          player.status = portalJSON[i].portal_status;
          break;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const formatPos = (pos) => {
    return pos ? pos + " | " : "";
  };

  const formatYear = (year) => {
    return year && year !== "NaN" ? year + " | " : "";
  };

  const formatHeight = (height) => {
    return height && height !== "NaN" ? height : "";
  };

  const formatWeight = (weight) => {
    return props.league === "mens" && weight ? " | " + weight : "";
  };

  // add in season dropdown
  function seasonFormat(year) {
    const newYear = year.substring(0, 4) + "-" + year.substring(7);
    return newYear;
  }

  const filteredList = query === "" ? "" : fuse.search(query.toLowerCase());

  const buildHeadshotPath = (headshotName, teamname) => {
    // Hacky fix for shared headshots
    let path = `${
      process.env.REACT_APP_STORAGE_BLOB_URL
    }/${props.league.substring(0, 1)}-headshots/${headshotName}.jpg`;
    let duplicateNamesList;
    if (props.league === "mens") {
      duplicateNamesList = duplicateNamesMen;
    } else {
      duplicateNamesList = duplicateNamesWomen;
    }
    for (let i = 0; i < duplicateNamesList.length; i++) {
      if (duplicateNamesList[i].name_bb_ref === headshotName) {
        path = `${
          process.env.REACT_APP_STORAGE_BLOB_URL
        }/${props.league.substring(
          0,
          1
        )}-headshots/${headshotName} ${teamname}.jpg`;
      }
    }
    return path;
  };

  const getStatusColor = (status) => {
    if (status === "In Portal") {
      return "#2FC68A";
    } else if (status === "Transferred") {
      return "#FE5634";
    } else {
      return "#d8d8d8";
    }
  };

  const handleRemoveClick = () => {
    props.updateScorerScore(null);
    props.updateShooterScore(null);
    props.updateFinisherScore(null);
    props.updatePlaymakerScore(null);
    props.updateRebounderScore(null);
    props.updateDefenderScore(null);
    props.updateRimprotectorScore(null);
    props.updateImpactScore(null);
    props.updateVisibility(false);
  };

  const handleSwitchPlayer = async (selectedPlayer) => {
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playerdata_v2?league=${props.league}&playerid=${selectedPlayer.playerid}&season=${selectedPlayer.season}`
    );
    let player = data.data[0];
    await buildPortalPlayersList(player, props.league); // set portal status
    player.headshotPath = buildHeadshotPath(
      player.headshotName,
      player.teamname
    );
    player.logoPath =
      process.env.REACT_APP_STORAGE_BLOB_URL +
      "/college-logos/" +
      player.teamname +
      ".jpg";
    player.school = player.teamname;
    player.year = player.playerclass;
    player.weight =
      props.league === "mens" && player.weight != 0
        ? player.weight + " lb"
        : "";
    props.updatePlayer(player);

    props.updateScorerScore(Math.round(player.scorer));
    props.updateShooterScore(Math.round(player.shooter));
    props.updateFinisherScore(Math.round(player.finisher));
    props.updatePlaymakerScore(Math.round(player.playmaker));
    props.updateRebounderScore(Math.round(player.rebounder));
    props.updateDefenderScore(Math.round(player.defender));
    props.updateRimprotectorScore(Math.round(player.rimprotector));
    props.updateImpactScore(Math.round(player.playervalue * 100));
    setQuery("");
    props.updateVisibility(true);
  };

  const handleSeasonSwitch = async (selectedSeason) => {
    let playerId = props.player.history[selectedSeason][0];
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playerdata_v2?league=${props.league}&playerid=${playerId}&season=${selectedSeason}`
    );

    let player = data.data[0];
    await buildPortalPlayersList(player, props.league); // set portal status
    player.headshotPath = buildHeadshotPath(
      player.headshotName,
      player.teamname
    );
    player.logoPath =
      process.env.REACT_APP_STORAGE_BLOB_URL +
      "/college-logos/" +
      player.teamname +
      ".jpg";
    player.school = player.teamname;
    player.year = player.playerclass;
    player.weight =
      props.league === "mens" && player.weight != 0
        ? player.weight + " lb"
        : "";
    props.updatePlayer(player);

    // Quick fix to make bars reanimate from 0 if score stays the same between seasons
    props.updateScorerScore(0);
    props.updateShooterScore(0);
    props.updateFinisherScore(0);
    props.updatePlaymakerScore(0);
    props.updateRebounderScore(0);
    props.updateDefenderScore(0);
    props.updateRimprotectorScore(0);
    props.updateImpactScore(0);

    props.updateScorerScore(Math.round(player.scorer));
    props.updateShooterScore(Math.round(player.shooter));
    props.updateFinisherScore(Math.round(player.finisher));
    props.updatePlaymakerScore(Math.round(player.playmaker));
    props.updateRebounderScore(Math.round(player.rebounder));
    props.updateDefenderScore(Math.round(player.defender));
    props.updateRimprotectorScore(Math.round(player.rimprotector));
    props.updateImpactScore(Math.round(player.playervalue * 100));
    setQuery("");
    props.updateVisibility(true);
  };

  const Row = ({ index, style }) => (
    <MenuItem
      style={style}
      key={filteredList[index].item.playerid}
      onClick={() => {
        handleSwitchPlayer(filteredList[index].item);
      }}
      sx={{
        "& .MuiTypography-root": {
          fontSize: props.isMobile ? "12px" : "16px",
        },
      }}
    >
      <ListItemText
        sx={{
          "& span": {
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "10px",
          },
        }}
      >
        {filteredList[index].item.name}
      </ListItemText>
    </MenuItem>
  );

  React.useEffect(() => {
    const seasonCount = Object.keys(props.player.history).length;
    const history = [];
    for (let i = 0; i < seasonCount; i++) {
      let season = Object.keys(props.player.history)[i];
      history.push(season);
    }
    setSeasons(history);
  }, [props.player.history]);

  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {!props.isMobile ? (
        <Box sx={{ marginBottom: "5px", width: "49%", display: "inline-grid" }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              overflow: "visible",
              height: "auto",
              padding: "10px",
              minHeight: "unset",
            }}
          >
            {headshotLoaded ? (
              ""
            ) : (
              <Skeleton
                variant="rounded"
                width={122}
                height={180}
                sx={{
                  float: "left",
                  marginRight: "20px",
                  borderRadius: "4px",
                }}
              />
            )}
            <CardMedia
              component="img"
              image={
                props.show
                  ? props.player.headshotPath
                  : process.env.REACT_APP_STORAGE_BLOB_URL +
                    "/" +
                    props.league.substring(0, 1) +
                    "-headshots/silhouette.png"
              }
              onError={(e) => {
                e.target.src =
                  process.env.REACT_APP_STORAGE_BLOB_URL +
                  "/" +
                  props.league.substring(0, 1) +
                  "-headshots/silhouette.png";
              }}
              onLoad={() => {
                setHeadshotLoaded(true);
              }}
              sx={{
                width: "20%",
                float: "left",
                marginRight: "10px",
                border: "1px solid #ececec",
                borderRadius: "4px",
                display: headshotLoaded ? "block" : "none",
              }}
            />
            {!props.show ? (
              <CardContent
                sx={{
                  padding: "0 !important",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "75%",
                }}
              >
                <Search sx={{ marginLeft: "0 !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <SearchIconWrapper
                      sx={{ marginLeft: { sm: "5px", md: "10px" } }}
                    >
                      <SearchIcon
                        sx={{
                          fontSize: {
                            sm: "1.2rem",
                            md: "1.2rem",
                            lg: "1.5rem",
                          },
                        }}
                      />
                    </SearchIconWrapper>
                    <StyledInputBase
                      sx={{ width: "fill-available" }}
                      placeholder="Search player to add"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(event) => setQuery(event.target.value)}
                      value={query}
                    />
                  </Box>
                  <Paper
                    sx={{
                      position: "absolute",
                      zIndex: "999",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      boxShadow: "0px 2px 4px rgb(0 0 0 / 20%)",
                    }}
                  >
                    <FixedSizeList
                      height={
                        filteredList.length > 9 ? 215 : filteredList.length * 36
                      }
                      width="100%"
                      itemSize={36}
                      itemCount={filteredList.length}
                      sx={{
                        overflowY: "hidden",
                        overflowX: "hidden",
                      }}
                    >
                      {Row}
                    </FixedSizeList>
                  </Paper>
                </Search>
              </CardContent>
            ) : (
              <CardContent
                sx={{
                  padding: "0 !important",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "end",
                    height: "20%",
                    paddingBottom: "5px",
                  }}
                >
                  <FormControl variant="standard" sx={{ mr: 1 }}>
                    <Select
                      value={
                        seasons.length > 0
                          ? seasonFormat(props.player.season)
                          : ""
                      }
                      sx={{
                        padding: "0 0 0 4px !important",
                        margin: "0px !important",
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          margin: "0px",
                        },
                        fontSize: "0.9rem",
                      }}
                    >
                      {seasons.map((season) => (
                        <MenuItem
                          value={seasonFormat(season)}
                          key={season}
                          onClick={() => handleSeasonSwitch(season)}
                        >
                          {seasonFormat(season)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    sx={{
                      background: "white",
                      mt: 3,
                      height: "30px",
                      margin: "0px",
                      padding: "5px 8px 5px 10px",
                    }}
                    variant="outlined"
                    size="small"
                    onClick={handleRemoveClick}
                    startIcon={<GroupRemoveIcon />}
                  >
                    Remove
                  </Button>
                </Box>
                {props.player.name === undefined ? (
                  <Skeleton
                    width="80%"
                    sx={{
                      height: "40%",
                      display: "flex",
                      alignItems: "flex-end",
                      paddingBottom: "10px",
                      fontWeight: "700",
                    }}
                  >
                    <StyledPlayerName variant="h4">.</StyledPlayerName>
                  </Skeleton>
                ) : (
                  <StyledPlayerName
                    variant="h4"
                    component="div"
                    sx={{
                      height: "40%",
                      display: "flex",
                      alignItems: "flex-end",
                      paddingBottom: "10px",
                      fontWeight: "700",
                      lineHeight: 1,
                      textWrap: "balance",
                    }}
                  >
                    <RadioButtonChecked
                      fontSize="xs"
                      sx={{
                        color: getStatusColor(props.player.status),
                        marginRight: "2px",
                        marginBottom: "0px",
                      }}
                    />
                    {props.player.name}
                  </StyledPlayerName>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px",
                    height: "40%",
                  }}
                >
                  {logoLoaded ? (
                    ""
                  ) : (
                    <Skeleton
                      variant="circular"
                      width={64}
                      height={64}
                      sx={{
                        float: "left",
                        margin: "0 10px 0 0",
                      }}
                    />
                  )}
                  <StyledLogo
                    component="img"
                    height="64"
                    image={props.player.logoPath}
                    onLoad={() => {
                      setLogoLoaded(true);
                    }}
                    sx={{
                      width: "unset",
                      float: "left",
                      margin: "0 10px 0 0",
                      display: logoLoaded ? "block" : "none",
                    }}
                  />
                  <Box sx={{ float: "left" }}>
                    {props.player.school === undefined ? (
                      <Skeleton width="200px">
                        <StyledBioText variant="h6">.</StyledBioText>
                      </Skeleton>
                    ) : (
                      <StyledBioText
                        variant="h6"
                        sx={{
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          textWrap: "pretty",
                        }}
                      >
                        {props.player.school}
                      </StyledBioText>
                    )}
                    {props.player.pos == undefined ||
                    props.player.year === undefined ||
                    props.player.height === undefined ||
                    props.player.weight === undefined ? (
                      <Skeleton width="100%">
                        <StyledBioText variant="h6">.</StyledBioText>
                      </Skeleton>
                    ) : (
                      <Box>
                        <StyledBioText
                          variant="h6"
                          sx={{ fontWeight: "300", lineHeight: "1.2em" }}
                        >
                          {formatPos(props.player.pos) +
                            formatYear(props.player.year) +
                            formatHeight(props.player.height) +
                            formatWeight(props.player.weight)}
                        </StyledBioText>
                      </Box>
                    )}
                  </Box>
                </Box>
              </CardContent>
            )}
          </Card>
        </Box>
      ) : (
        // MOBILE BIO
        <Box sx={{ marginBottom: "5px", width: "50%" }}>
          <Card
            sx={{
              alignItems: "center",
              overflow: "visible",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              boxShadow: "none",
            }}
          >
            {headshotLoaded ? (
              ""
            ) : (
              <Skeleton
                variant="rounded"
                width={75}
                height={75}
                sx={{
                  borderRadius: "4px",
                }}
              />
            )}
            <CardMedia
              component="img"
              width="75px"
              height="75px"
              image={
                props.show
                  ? props.player.headshotPath
                  : process.env.REACT_APP_STORAGE_BLOB_URL +
                    "/" +
                    props.league.substring(0, 1) +
                    "-headshots/silhouette.png"
              }
              onError={(e) => {
                e.target.src =
                  process.env.REACT_APP_STORAGE_BLOB_URL +
                  "/" +
                  props.league.substring(0, 1) +
                  "-headshots/silhouette.png";
              }}
              onLoad={() => {
                setHeadshotLoaded(true);
              }}
              sx={{
                width: "75px",
                maxWidth: "75px",
                border: "1px solid #ececec",
                borderRadius: "4px",
                display: headshotLoaded ? "block" : "none",
              }}
            />
            {!props.show ? (
              <CardContent
                sx={{
                  padding: "0 !important",
                  height: "164px",
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <SearchMobile>
                    <StyledInputBaseMobile
                      placeholder="SEARCH"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(event) => setQuery(event.target.value)}
                      value={query}
                      onKeyDown={(e) => onKeyDown(e)}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{
                              fontSize: 22,
                              color: "#5B5B5B",
                              marginLeft: "5px",
                            }}
                          />
                        </InputAdornment>
                      }
                      sx={{
                        input: {
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </SearchMobile>
                  <Paper
                    sx={{
                      width: "100%",
                      maxHeight: "215px",
                      position: "absolute",
                      zIndex: "999",
                      left: "0px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      boxShadow: "0px 2px 4px rgb(0 0 0 / 20%)",
                      textAlign: "left",
                    }}
                  >
                    <FixedSizeList
                      height={
                        filteredList.length > 9 ? 215 : filteredList.length * 36
                      }
                      width={"100%"}
                      itemSize={36}
                      itemCount={filteredList.length}
                      sx={{
                        overflowY: "hidden",
                        overflowX: "hidden",
                      }}
                    >
                      {Row}
                    </FixedSizeList>
                  </Paper>
                </Box>
              </CardContent>
            ) : (
              <CardContent
                sx={{
                  padding: "0 !important",
                  height: "164px",
                }}
              >
                <Box
                  sx={{
                    mt: "5px",
                    height: "124px",
                  }}
                >
                  <Box
                    sx={{
                      height: "84px",
                    }}
                  >
                    {props.player.name === undefined ? (
                      <Skeleton
                        width="80%"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "700",
                        }}
                      >
                        <StyledPlayerName variant="h4">.</StyledPlayerName>
                      </Skeleton>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          marginTop: "8px",
                          marginBottom: "6px",
                        }}
                      >
                        <RadioButtonChecked
                          fontSize="12px"
                          sx={{
                            color: getStatusColor(props.player.status),
                            marginRight: "2px",
                          }}
                        />
                        <StyledPlayerName>{props.player.name}</StyledPlayerName>
                      </Box>
                    )}
                    {props.player.school === undefined ? (
                      <Skeleton width="200px">
                        <StyledBioText variant="h6">.</StyledBioText>
                      </Skeleton>
                    ) : (
                      <StyledBioText
                        variant="h6"
                        sx={{
                          fontWeight: "300",
                          lineHeight: 1.1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {props.player.school}
                      </StyledBioText>
                    )}
                    {props.player.pos == undefined ||
                    props.player.year === undefined ||
                    props.player.height === undefined ||
                    props.player.weight === undefined ? (
                      <Skeleton width="100%">
                        <StyledBioText>.</StyledBioText>
                      </Skeleton>
                    ) : (
                      <StyledBioText
                        sx={{ fontWeight: "300", lineHeight: 1.6 }}
                      >
                        {formatPos(props.player.pos) +
                          formatYear(props.player.year) +
                          formatHeight(props.player.height) +
                          formatWeight(props.player.weight)}
                      </StyledBioText>
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{
                        background: "white",
                      }}
                      variant="outlined"
                      size="small"
                      onClick={handleRemoveClick}
                      startIcon={<GroupRemoveIcon />}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControl variant="standard" sx={{ m: 0, margin: 5}}>
                    <Select
                      value={
                        seasons.length > 0
                          ? seasonFormat(props.player.season)
                          : ""
                      }
                      sx={{
                        padding: "0px !important",
                        margin: "0px !important",
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          margin: "0px",
                        },
                        zIndex: "9999 !important",
                        //backgroundColor: "red",
                      }}
                      MenuProps={{ disablePortal: true }}
                    >
                      {seasons.map((season) => (
                        <MenuItem
                          value={seasonFormat(season)}
                          key={season}
                          onClick={() => handleSeasonSwitch(season)}
                        >
                          {seasonFormat(season)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </CardContent>
            )}
          </Card>
        </Box>
      )}
    </>
  );
}

export default ModalBio;
